<template>
  <v-container class="my-4" :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'}; background-color: #fff;`">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <h2 class="mb-2">{{ $t("QUY CHẾ HOẠT ĐỘNG CỦA WEBSITE THƯƠNG MẠI ĐIỆN TỬ CONASI365.VN") }}</h2>
        <div class="text-left">
            <p class="font-weight-bold">I. NGUYÊN TẮC CHUNG</p>
            <p>Website CONASI365.vn do Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi (Công ty) thực hiện hoạt động và vận hành. Thành viên trên CONASI365.vn (gồm có nhà cung cấp và khách hàng mua) là các thương nhân, tổ chức, cá nhân có hoạt động thương mại hợp pháp được CONASI365.vn chính thức công nhận và được phép sử dụng dịch vụ do CONASI365.vn và các bên liên quan cung cấp.</p>
            <p>Bản quy chế này ban hành các quy định bắt buộc áp dụng đối với các tổ chức, cá nhân tham gia giao dịch trên CONASI365.vn với mục đích tạo ra một môi trường hoạt động để phục vụ nhu cầu mua bán các sản phẩm dịch vụ du lịch thông qua website CONASI365.vn;</p>
            <p>Các tổ chức, cá nhân tham gia giao dịch trên CONASI365.vn có trách nhiệm thực hiện đúng nội dung quy chế, đề án hoạt động của CONASI365.vn và quy định của pháp luật;</p>
            <p>Thông tin về sản phẩm dịch vụ được đăng tải trên CONASI365.vn đáp ứng đầy đủ các quy định của pháp luật có liên quan, không thuộc các trường hợp cấm theo quy định của pháp luật. </p>
            <p>Hoạt động mua bán sản phẩm và cung cấp dịch vụ qua website CONASI365.vn phải được thực hiện công khai, minh bạch, đảm bảo quyền lợi của khách hàng.</p>
            <p>Tất cả các nội dung trong Quy chế này phải tuân thủ theo hệ thống pháp luật hiện hành của Việt Nam. Thành viên khi tham gia vào CONASI365.vn phải tự tìm hiểu trách nhiệm pháp lý của mình đối với luật pháp hiện hành của Việt Nam và cam kết thực hiện đúng những nội dung trong Quy chế của CONASI365.vn.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">II. QUY ĐỊNH CHUNG</p>
            <p class="font-weight-bold">1. Tên miền website cung cấp dịch vụ Thương mại điện tử :</p>
            <p>Website cung cấp dịch vụ thương mại điện Conasi365.vn do Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi phát triển với tên gọi: Conasi365.vn (sau đây gọi tắt là: “Website Conasi365.vn”). </p>
            <p class="font-weight-bold">2. Định nghĩa chung:</p>
            <p><span class="font-weight-bold font-italic">- Nhà cung cấp:</span> là thương nhân, tổ chức có nhu cầu hợp tác để đăng thông tin về các chương trình ưu đãi, khuyến mại về dịch vụ phòng khách sạn, khu nghỉ dưỡng, các tour du lịch, vé máy bay (dự kiến) … lên website CONASI365.vn.</p>
            <ul>
                <li>Nhà cung cấp được hỗ trợ để đăng tin cung cấp dịch vụ trong lĩnh vực lưu trú, phòng khách sạn, du lịch, bán vé máy bay…..theo đúng quy chuẩn đã công bố của CONASI365.vn và theo cam kết của nhà cung cấp khi thỏa thuận hợp tác.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">- Khách hàng/Người mua:</span> là thương nhân, tổ chức, cá nhân có nhu cầu tìm kiếm các sản phẩm dịch vụ hoặc các chương trình ưu đãi, khuyến mại về du lịch.</p>
            <ul>
                <li>Người mua có thể tạo một tài khoản cá nhân để sử dụng trên website CONASI365.vn.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">- Thành viên:</span> bao gồm cả khách hàng người mua và nhà cung cấp dịch vụ.</p>
            <ul>
                <li>Thành viên tham gia giao dịch trên CONASI365.vn là thương nhân, tổ chức, cá nhân có nhu cầu mua hoặc bán sản phẩm dịch vụ du lịch.</li>
                <li>Thành viên phải đăng ký và cần cung cấp các thông tin cá nhân có liên quan, được Ban quản lý Conasi chính thức công nhận và được phép sử dụng dịch vụ trên website CONASI365.vn. có thể tạo một tài khoản cá nhân của mình để sử dụng.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">E-voucher:</span> là dạng phiếu sử dụng dạng điện tử được gửi qua Email/SMS bao gồm các thông tin và điều kiện sử dụng các chương trình ưu đãi, các chương trình khuyến mại của Nhà cung cấp được cấp phép tổ chức bởi cơ quan quản lý và được đăng tải lên website CONASI365.vn để giới thiệu/bán cho khách hàng mua.</p>
            <p class="mt-4"><span class="font-weight-bold font-italic">Sản phẩm:</span> là một hoặc nhiều dịch vụ, chương trình khuyến mại, voucher được đăng tải trong danh mục trên website CONASI365.vn.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">III. QUY TRÌNH GIAO DỊCH</p>
            <p class="font-weight-bold">1. Quy trình mua hàng dành cho người mua</p>
            <p><span class="font-weight-bold font-italic">- Bước 1: </span>Người mua có thể đăng ký thành viên và đăng nhập theo hướng dẫn của CONASI365.vn. Tài khoản test: testid.tmdt@gmail.com – Pass:123456</p>
            <p><span class="font-weight-bold font-italic">- Bước 2: </span>Người mua có thể tìm kiếm các sản phẩm, dịch vụ, khuyến mại phù hợp được đăng tải trên website CONASI365.vn.</p>
            <p><span class="font-weight-bold font-italic">- Bước 3: </span>Xem thông tin chi tiết và chọn gói dịch vụ. Chọn “Thêm sản phẩm” hoặc ”Mua ngay”.</p>
            <p><span class="font-weight-bold font-italic">- Bước 4: </span>Chọn “Thanh toán”.</p>
            <p><span class="font-weight-bold font-italic">- Bước 5: </span>Nhập thông tin cá nhân và địa chỉ giao hàng (ở đây là địa chỉ email).</p>
            <p><span class="font-weight-bold font-italic">- Bước 6: </span>Chọn phương thức vận chuyển và thanh toán (nhập mã giám giá nếu có).</p>
            <p><span class="font-weight-bold font-italic">- Bước 7: </span>Chọn “Đặt hàng”. Hệ thống xác nhận đặt hàng thành công. Người mua thực hiện thanh toán theo thông tin đơn hàng.</p>
            <p><span class="font-weight-bold font-italic">- Bước 8: </span>Người mua nhận E-Voucher từ CONASI365.vn và đến địa điểm của nhà cung cấp để sử dụng dịch vụ hoặc thụ hưởng chương trình khuyến mại.</p>
            <p>Người mua có thể tra cứu thông tin chi tiết của Voucher đã đặt mua dựa trên số seri, thông tin email và điện thoại đã đăng ký:</p>
            <ul>
                <li>Vào mục “Tra cứu Voucher”.</li>
                <li>Nhập các thông tin và chọn “Tìm kiếm”.</li>
            </ul>
            <p class="font-weight-bold mt-4">2. Quy trình dành cho nhà cung cấp</p>
            <p><span class="font-weight-bold font-italic">- Bước 1: </span>Người bán/ nhà cung cấp liên hệ và gửi yêu cầu đến Công ty. Khi nhận được yêu cầu chúng tôi sẽ tiến hành liên lạc đến nhà cung cấp để thỏa thuận.</p>
            <p><span class="font-weight-bold font-italic">- Bước 2: </span>Conasi và nhà cung cấp ký hợp đồng cung ứng dịch vụ;</p>
            <p><span class="font-weight-bold font-italic">- Bước 3: </span>Nhà cung cấp gửi các thông tin đến bộ phận hỗ trợ của CONASI365.vn kiểm duyệt và đăng tải dịch vụ, các chương trình khuyến mại, ưu đãi của Nhà cung cấp lên website CONASI365.vn.</p>
            <ul>
                <li>Thông tin các voucher giảm giá, các chương trình khuyến mại, chi tiết sản phẩm dịch vụ khuyến mại, chất lượng hàng hóa, giá cả, ngày giờ khuyến mại. địa điểm khuyến mại ...</li>
                <li>Các giấy phép hoạt động kinh doanh, giấy phép các chương trình khuyến mại, quảng cáo đã được cơ quan có thẩm quyền cấp phép.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">- Bước 4: </span>Nhà cung cấp tiếp nhận và cung ứng dịch vụ cho các khách hàng sau khi khách hàng mua dịch vụ, voucher từ website CONASI365.vn.</p>
            <p><span class="font-weight-bold font-italic">- Bước 5: </span>Người bán/nhà cung cấp phối hợp với Công ty để giải quyết các vấn đề tranh chấp liên quan đến sản phẩm/dịch vụ mà nhà cung cấp bán cho khách hàng (nếu có).</p>
            <p class="font-weight-bold mt-4">3. Chính sách giao nhận, vận chuyển</p>
            <p>CONASI365.vn sẽ gửi thông tin xác nhận đơn hàng qua email/sms dưới hình thức voucher điện tử/phiếu thông tin điện tử cho khách hàng. Khách hàng phải cung cấp chính xác email và số điện thoại liên hệ khi đăng ký thành viên hoặc đặt hàng để hệ thống gửi thông tin. </p>
            <p>Voucher điện tử mà CONASI365.vn sẽ gửi cho khách hàng là một phiếu sử dụng dịch vụ đã được quy định với nhà cung cấp, trong đó bao gồm các thông tin cụ thể của dịch vụ mà khách đặt mua. </p>
            <p>Sau khi khách hàng hoàn tất thanh toán, hệ thống sẽ hiển thị Voucher trong tài khoản cá nhân mà khách hàng đăng ký trên CONASI365.vn. Khách hàng có thể tra cứu bằng cách đăng nhập vào tài khoản của mình trên hệ thống, hoặc tra cứu bằng email và số điện thoại đã đăng ký. Đồng thời, voucher điện tử cũng được gửi đến hòm thư điện tử (email) hoặc SMS cho khách hàng. Voucher có thể được in ra từ hệ thống của CONASI365.vn hoặc khách hàng có thể sử dụng phiếu điện tử đã nhận qua email để đặt phòng và sử dụng dịch vụ tại khách sạn/khu nghỉ dưỡng.</p>
            <p>Với các dịch vụ lưu trú tại các khách sạn/khu nghỉ dưỡng, khách hàng cần chủ động liên hệ nhà cung cấp để đặt phòng trước khi tới sử dụng dịch vụ và hưởng ưu đãi. Trong trường hợp gặp khó khăn khi đặt lịch, khách hàng có thể gửi yêu cầu tới Conasi để được hỗ trợ đặt phòng.</p>
            <p>Khi khách hàng không được cung cấp các ưu đãi, chương trình khuyến mại theo thông tin nhận được trên phiếu sử dụng dịch vụ, khách hàng có thể liên hệ với bộ phận chăm sóc khách hàng của CONASI365.vn để phản ánh. Công ty sẽ có biện pháp xử lý và hỗ trợ khách hàng để được sử dụng dịch vụ theo voucher đã phát hành. </p>
            <p>Nhà cung cấp khi gửi thông tin đề nghị phải mô tả về thời gian cung cấp dịch vụ, các điều kiện và quy định liên quan đến các chương trình khuyến mại để CONASI365.vn đăng tải công khai lên website.</p>
            <p class="font-weight-bold mt-4">4. Chính sách đổi trả hàng</p>
            <p>Các voucher/gói dịch vụ được đặt mua thông qua trang CONASI365.vn sẽ không được đổi trả. Khách hàng chỉ được đổi lịch đặt phòng, lịch đặt các dịch vụ với nhà cung cấp. Chính sách đổi lịch sẽ  tùy thuộc vào mỗi khách sạn/khu nghỉ dưỡng. Công ty sẽ có trách nhiệm hỗ trợ khách hàng về việc đổi trả theo quy định được đăng tải ở mỗi dịch vụ hoặc chính sách của mỗi nhà cung cấp.</p>
            <p>Thời gian cho phép đổi trả là 07 ngày trước thời gian nhận phòng. Tùy từng trường hợp và phụ thuộc vào điều kiện khách quan mà nhà cung cấp có thể hạn chế điều kiện đổi trả. Khách hàng có thể tham khảo các chính sách về đổi trả cho các sản phẩm/ dịch vụ bằng cách xem chi tiết các điều khoản/ điều kiện trong từng bài đăng của dịch vụ trên CONASI365.vn, hoặc liên hệ trực tiếp với nhà cung cấp để hỏi thông tin về việc hủy, dời lịch hẹn sử dụng dịch vụ. Khách hàng cần lưu ý về chi phí phát sinh (nếu có) của việc đổi trả dịch vụ.</p>
            <p>Quy trình thực hiện: khách hàng liên hệ với khách sạn/ khu nghỉ dưỡng để yêu cầu việc thay đổi lịch hẹn đã đặt. Nếu vấn đề không được giải quyết thỏa đáng, khách hàng có thể liên hệ với Ban Quản trị Công ty để được hỗ trợ phương án giải quyết phù hợp nhất.</p>
            <p>Khi phát sinh việc đổi trả, khách hàng có thể liên hệ đến bộ phận chăm sóc khách hàng của CONASI365.vn để được hướng dẫn cũng như nhận được nhiều thông tin tốt nhất từ đối tác.</p>
            <ul>
                <li>Phòng ban: Chăm sóc khách hàng</li>
                <li>Email: info@conasi365.vn</li>
                <li>Điện thoại: 1900 63 68 70</li>
            </ul>
            <p class="font-weight-bold mt-4">5. Chính sách bảo hành</p>
            <p>Với các gói dịch vụ hoặc voucher khuyến mại, khách hàng mua trên CONASI365.vn sẽ được bảo hành theo chính sách của từng nhà cung cấp đưa ra. Nhà cung cấp phải cam kết cung cấp đúng chất lượng đã công bố trên website.</p>
            <p>Chúng tôi đề nghị khách hàng tìm hiểu kỹ về điều kiện, cách thức bảo hành gói dịch vụ, các chương trình khuyến mại trong mỗi bài đăng trên CONASI365.vn. Nếu khách hàng thấy dịch vụ, các ưu đãi nhận được không đúng cam kết vui lòng gửi phản hồi/ khiếu nại về cho CONASI365.vn để được hỗ trợ nhằm đảm bảo quyền lợi thỏa đáng.</p>
            <p>Nếu các nhà cung cấp (khách sạn/khu nghỉ dưỡng) không cung cấp các chương trình ưu đãi, khuyến mại như công bố, CONASI365.vn có trách nhiệm đứng ra yêu cầu nhà cung cấp thực hiện các dịch vụ như cam kết cho khách hàng.</p>
            <p>Trong trường hợp nhà cung cấp từ chối việc thực hiện cung cấp dịch vụ cho khách hàng, Công ty có trách nhiệm về việc bồi thường các chi phí thiệt hại cho khách hàng, và làm việc với bên nhà cung cấp để đưa ra các thỏa thuận đảm bảo cho quyền lợi của khách hàng của Công ty.</p>
            <p>Các yêu cầu về bồi thường chi phí sẽ được xử lý và xác nhận sau 2-5 ngày làm việc. Trong trường hợp đủ điều kiện, tiền sẽ được hoàn lại trong vòng 14 ngày làm việc.</p>
            <p>Nếu dịch vụ khách hàng đã chọn và đặt mua bị hủy do lỗi phát sinh từ CONASI365.vn hoặc Nhà cung cấp gây ra, khoản thanh toán đã thực hiện sẽ được hoàn lại cho khách hàng giống như cách đã thanh toán. Số tiền được hoàn lại có thể khác với số tiền khách trả ban đầu do tỷ giá hối đoái, hoặc phí quản lý hoặc phí dịch vụ do công ty phát hành thẻ tín dụng hoặc công ty xử lý thanh toán.</p>
            <p class="font-weight-bold mt-4">6. Quy trình giải quyết tranh chấp, khiếu nại</p>
            <p>Công ty và Nhà cung cấp luôn có trách nhiệm tiếp nhận và xử lý khiếu nại của Khách hàng liên quan đến giao dịch tại website CONASI365.vn.</p>
            <p>Khách hàng có quyền gửi khiếu nại về việc chất lượng sản phẩm, dịch vụ không đúng với mô tả, về vấn đề đặt lịch/đặt phòng, về vấn đề thanh toán đến Ban quản trị của website CONASI365.vn. Thành viên nên gửi khiếu nại ngay trong vòng 03 ngày làm việc kể từ khi phát sinh sự việc. Đối với các khiếu nại khác, thành viên có thể gửi ý kiến để góp phần cho website CONASI365.vn tốt hơn khi phát hiện có vấn đề chưa rõ ràng hay hành vi có dấu hiệu vi phạm. Khi tiếp nhận những phản hồi này, Công ty sẽ xác nhận lại thông tin, trường hợp đúng như phản ánh của Khách hàng tùy theo mức độ, Công ty sẽ có những biện pháp xử lý kịp thời.</p>
            <p class="font-weight-bold font-italic">- Các cách thức gửi khiếu nại như sau:</p>
            <ul>
                <li>(i) Gửi thư điện tử đến địa chỉ email: info@conasi365.vn</li>
                <li>(ii) Gọi điện thoại đến Hotline: 1900 63 68 70</li>
                <li>(iii) Gửi khiếu nại trực tiếp (Khách hàng đến trực tiếp hoặc gửi khiếu nại thông qua đường bưu điện) đến địa chỉ: </li>
            </ul>
            <p class="mt-4">1. Tên đơn vị: Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi</p>
            <p>2. Địa chỉ: Lầu 8, 58 Nguyễn Đình Chiểu, P Đa Kao, Quận 1, TPHCM</p>
            <p class="font-weight-bold font-italic">- Trình tự giải quyết tranh chấp, khiếu nại:</p>
            <p><span class="font-weight-bold font-italic">Bước 1: </span>Bộ phận Chăm Sóc Khách Hàng của Công ty sẽ tiếp nhận các khiếu nại của Thành viên được gửi đúng theo các phương thức nêu trên.</p>
            <ul>
                <li>Công ty sẽ yêu cầu Khách hàng và/hoặc Nhà cung cấp cung cấp các thông tin, bằng chứng liên quan đến giao dịch, sản phẩm để xác minh, làm rõ vụ việc và có hướng xử lý thích hợp. </li>
                <li>Công ty đối chiếu thông tin trên hệ thống (lịch sử xuất voucher, mã số, ngày giờ, nhà cung cấp, đợt phát hành, …) và đối chiếu với thông tin thanh toán của Bộ phận Kế toán.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">Bước 2: </span>Bộ phận Chăm Sóc Khách Hàng sẽ trả lời/hồi đáp dựa trên các chính sách mà Công ty đã công bố. Cách thức trả lời thông qua email hoặc điện thoại đến thành viên đó.</p>
            <p><span class="font-weight-bold font-italic">Bước 3: </span>Chuyển kết quả trả lời/hồi đáp khiếu nại cho các bộ phận có liên quan nếu (a) phải làm việc với nhà cung cấp để đảm bảo cung cấp dịch vụ cho khách hàng, (b) hoàn tiền cho thành viên và (c) các trường hợp khác cần hỗ trợ từ các bộ phận liên quan để Công ty giải quyết quyền lợi cho thành viên.</p>
            <p class="font-weight-bold font-italic">- Thời hạn xử lý khiếu nại:</p>
            <p>Thời gian tiếp nhận khiếu nại là 02-03 ngày. Thời gian trả lời/hồi đáp đối với khiếu nại tối đa là 15 ngày làm việc kể từ ngày tiếp nhận khiếu nại. Nếu thành viên khiếu nại chưa cung cấp đủ thông tin theo yêu cầu của trong thời hạn để giải quyết khiếu nại, Conasi sẽ hiểu rằng thành viên đó đã từ bỏ việc khiếu nại và vụ việc sẽ được đóng lại khi kết thúc thời hạn 15 ngày làm việc nêu trên mà không có lỗi của Công ty.</p>
            <p>Trong trường hợp có yêu cầu của thành viên đưa vụ việc ra cơ quan Nhà Nước có thẩm quyền để giải quyết theo pháp luật, thời hạn xử lý sẽ theo thời hạn giải quyết của cơ quan nhà nước có thẩm quyền. </p>
            <p class="font-weight-bold font-italic">- Trách nhiệm mỗi bên:</p>
            <p>Các bên bao gồm Nhà cung cấp, Khách hàng và Công ty sẽ phải có vai trò trách nhiệm trong việc tích cực giải quyết vấn đề:</p>
            <ul>
                <li>Đối với Nhà cung cấp, cần có trách nhiệm cung cấp văn bản giấy tờ chứng thực thông tin liên quan đến sự việc đang gây mâu thuẫn cho khách hàng;</li>
                <li>Đối với Conasi sẽ lắng nghe và tiếp nhận thông tin từ khách hàng (trong trường hợp cụ thể chúng tôi sẽ: yêu cầu khách hàng cần có trách nhiệm cung cấp chính xác các thông tin vô hình và hữu hình về vấn đề mâu thuẫn đang phát sinh cần giải quyết mà khách hàng đã tự thấy mình bị thiệt hại) và Nhà cung cấp, sau đó tiến hành xem xét và nêu rõ, phân tích lỗi thuộc về bên nào. Lấy ý kiến về sự thỏa thuận mức độ bồi hoàn của hai bên và kết thúc giải quyết tranh chấp một cách thỏa đáng nhất.</li>
                <li>Đối với Khách hàng: cung cấp đầy đủ, chính xác các thông tin, bằng chứng liên quan đến giao dịch, sản phẩm theo yêu cầu của Công ty để xác minh, làm rõ vụ việc và có hướng xử lý thích hợp. </li>
            </ul>
            <p class="font-weight-bold font-italic mt-4">- Hướng xử lý:</p>
            <p>Trong trường hợp chứng minh được lỗi thuộc về Nhà cung cấp, Công ty sẽ có biện pháp cảnh cáo, yêu cầu bồi hoàn lại toàn bộ chi phí mà thành viên đã phải bỏ ra để sở hữu hàng hóa/dịch vụ đó hoặc phải đổi lại sản phẩm, dịch vụ đúng với chất lượng mà Nhà cung cấp thông báo tại website CONASI365.vn. Nếu Nhà cung cấp tái phạm, Công ty sẽ chấm dứt và gỡ bỏ toàn bộ những thông tin, chương trình khuyến mại của Nhà cung cấp đó trên website CONASI365.vn.</p>
            <p>Trường hợp Công ty và các bên đã nỗ lực giải quyết qua hình thức thương lượng, thỏa thuận mà vẫn không thể giải quyết được mâu thuẫn phát sinh từ giao dịch giữa Khách hàng và Nhà cung cấp, thì một trong hai bên Khách hàng và Nhà cung cấp sẽ có thể đưa vụ việc đến Cơ quan Nhà nước có thẩm quyền giải quyết nhằm đảm bảo lợi ích hợp pháp của các bên.</p>
            <p>Công ty tôn trọng và nghiêm túc thực hiện các quy định của pháp luật về bảo vệ quyền lợi của Khách hàng. Vì vậy, đề nghị các Nhà cung cấp trên website CONASI365.vn cung cấp đầy đủ, chính xác, trung thực và chi tiết các thông tin liên quan đến sản phẩm, dịch vụ. Mọi hành vi lừa đảo, gian lận trong kinh doanh đều bị lên án và phải chịu hoàn toàn trách nhiệm trước pháp luật.
            Công ty công khai cơ chế giải quyết các tranh chấp phát sinh trong quá trình giao dịch trên website CONASI365.vn. Khi Khách hàng mua hàng hóa hoặc dịch vụ phát sinh mâu thuẫn với Nhà cung cấp hoặc bị tổn hại lợi ích hợp pháp. </p>
            <p>Công ty công khai cơ chế và quy trình giải quyết tranh chấp đối với các bên liên quan là: giải quyết tranh chấp theo cơ chế trao đổi thỏa thuận thống nhất. Các bên liên quan sẽ thực hiện theo quy trình trao đổi gián tiếp qua điện thoại, xác nhận bằng email, nếu vẫn chưa thỏa thuận được thì sẽ giải quyết thông qua gặp trực tiếp để cụ thể hóa vấn đề, giải quyết triệt để vấn đề mâu thuẫn giữa các bên sao cho có lợi nhất.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">IV. QUY TRÌNH THANH TOÁN</p>
            <p class="font-weight-bold">1. Quy trình thanh toán của khách hàng mua trên CONASI365.vn</p>
            <p><span class="font-weight-bold font-italic">Bước 1: </span>Khách hàng tìm hiểu thông tin về các sản phẩm dịch vụ/voucher khuyến mãi được đăng tải trên website;</p>
            <p><span class="font-weight-bold font-italic">Bước 2: </span>Khách hàng đặt hàng, cung cấp các thông tin cần thiết.</p>
            <p><span class="font-weight-bold font-italic">Bước 3: </span>Chọn phương thức thanh toán sẵn có.</p>
            <p>Sau khi khách hàng hoàn tất thanh toán, hệ thống sẽ gửi thông tin sản phẩm dịch vụ và voucher đến hòm thư điện tử (email) hoặc SMS mà khách hàng đã đăng ký. Voucher có thể được in ra từ hệ thống của CONASI365.vn hoặc sử dụng vé điện tử đã nhận qua email để làm thủ tục check in dịch vụ tại nhà cung cấp.</p>
            <p class="font-weight-bold">2. Quy trình thanh toán giữa Đối tác/Nhà cung cấp và Công ty</p>
            <p>Công ty thu phí hoa hồng của Nhà cung cấp dựa theo đơn hàng/voucher đã bán qua website CONASI365.vn.</p>
            <p>Phí hoa hồng được tính bằng một tỷ lệ hoa hồng thống nhất nhân với giá bán ra của dịch vụ/voucher được giao dịch trực tuyến qua website CONASI365.vn. Tỷ lệ hoa hồng được quy định cụ thể trong hợp đồng với mỗi bên nhà cung cấp. (Giá bán đã bao gồm thuế).</p>
            <p><span class="font-weight-bold font-italic">- Tỉ lệ hoa hồng: </span>theo thoả thuận tại Hợp đồng với từng Nhà cung cấp</p>
            <ul>
                <li>Các bên sẽ kiểm tra, đối chiếu dữ liệu các giao dịch thành công theo định kỳ để thanh toán phí hoa hồng, lịch trình cụ thể như sau:</li>
            </ul>
            <p class="font-weight-bold font-italic mt-4">- Kiểm tra dữ liệu:</p>
            <ul>
                <li>Sau 03 ngày kể từ thời điểm kết thúc Kỳ phát hành E-voucher, nhà cung cấp gửi cho Công ty qua địa chỉ email Bảng kê chi tiết số lượng E-voucher đã giao dịch thành công.</li>
                <li>Công ty kiểm tra, xác nhận các nội dung trên Bảng kê chi tiết và gửi xác nhận qua email cho Nhà cung cấp trong thời hạn tối đa 05 (năm) ngày làm việc kể từ ngày nhận được Bảng kê chi tiết số lượng E-voucher đã giao dịch.</li>
            </ul>
            <p class="font-weight-bold font-italic mt-4">- Thời hạn thanh toán:</p>
            <ul>
                <li>Trong vòng tối đa 14 ngày làm việc kể từ ngày nhận được yêu cầu thanh toán, Conasi thanh toán cho nhà cung cấp một lần toàn bộ số tiền phí dịch vụ đã thu của khách hàng sau khi trừ đi hoa hồng dịch vụ. </li>
                <li>Mỗi Bên sẽ chịu phí ngân hàng và nghĩa vụ thuế có liên quan của mỗi Bên.</li>
                <li>Mỗi bên có trách nhiệm xuất hóa đơn GTGT theo luật áp dụng.</li>
            </ul>
            <p class="mt-4"><span class="font-weight-bold font-italic">- Phương thức thanh toán: </span>Chuyển khoản</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">V. ĐẢM BẢO AN TOÀN GIAO DỊCH</p>
            <p>CONASI365.vn có trách nhiệm bảo đảm an toàn giao dịch qua website bằng những biện pháp kỹ thuật và công cụ pháp lý phù hợp với quy định của pháp luật;</p>
            <p>CONASI365.vn cam kết thực hiện mọi biện pháp trong khả năng để ngăn ngừa những hành vi sau: Đánh cắp hoặc tiếp cận thông tin trái phép; Sử dụng thông tin trái phép; Thay đổi, phá hủy thông tin trái phép; chiếm đoạt tài khoản hoặc những hành khác làm ảnh hưởng đến an toàn giao dịch trên CONASI365.vn.</p>
            <p>Công ty sử dụng việc thẩm định doanh nghiệp để bảo vệ thông tin thanh toán và thông tin cá nhân của thành viên. Để bảo đảm rằng giao dịch được thực hiện thành công, và giảm thiểu rủi ro có thể phát sinh, Công ty tuân theo đúng các thủ tục sau đây:</p>
            <ul>
                <li>Bảo vệ thành viên: Hệ thống đặt hàng được thiết kế sao cho các thành viên của Công ty không thể đặt hàng cho đến khi thành viên được an toàn khi đặt hàng.</li>
                <li>Ngay khi thành viên nhấp chuột vào nút ‘Mua ngay’ của trình duyệt thì việc đặt hàng sẽ ở chế độ an toàn. Mọi dữ liệu có liên quan đến các đơn hàng của thành viên, bao gồm tất cả các thông tin cá nhân, và chi tiết thanh toán, sẽ đi qua tất cả định dạng được mã hoá theo tiêu chuẩn của Công ty.</li>
                <li>Thành viên không nên cung cấp thông tin chi tiết về các khoản thanh toán cho bất kỳ ai qua thư điện tử hoặc các phương tiện khác. Công ty không chịu trách nhiệm đối với bất kỳ tổn thất nào mà thành viên có thể phải chịu do việc trao đổi thông tin có liên quan đến việc thanh toán bằng bất kỳ phương tiện nào.</li>
            </ul>
            <p class="mt-4">Trong trường hợp hệ thống thông tin của CONASI365.vn bị tấn công làm phát sinh nguy cơ mất thông tin của thành viên, Công ty sẽ thông báo cho cơ quan chức năng trong vòng 24 (hai mươi bốn) giờ sau khi phát hiện sự cố để cùng phối hợp xử lý.</p>
            <p>Ngoài ra, Công ty tuyệt đối không cho phép bất kỳ ai được sử dụng bất kỳ hình thức nào để can thiệp vào hệ thống hay làm thay đổi cấu trúc dữ liệu trên Công ty. Chúng tôi nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống website. Mọi vi phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước pháp luật nếu cần thiết. Mọi thông tin của thành viên tại CONASI365.vn sẽ được chúng tôi bảo mật nhưng trong trường hợp pháp luật yêu cầu, chúng tôi buộc phải cung cấp thông tin này cho cơ quan pháp luật.</p>
            <p>Trường hợp thành viên gửi khiếu nại có liên quan đến việc an toàn giao dịch trên CONASI365.vn. Ngay khi tiếp nhận thông tin, CONASI365.vn sẽ nhanh chóng thực hiện việc kiểm tra, xác minh. Trong trường hợp đúng như phản ánh của thành viên thì CONASI365.vn sẽ liên hệ trực tiếp với thành viên để xử lý vụ việc trên tin thần khắc phục và thiện chí. Trường hợp hai bên không thể tự thoả thuận thì sẽ đưa ra Tòa án Nhân Dân có thẩm quyền để giải quyết.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">VI. BẢO VỆ THÔNG TIN CÁ NHÂN KHÁCH HÀNG:</p>
            <p class="font-weight-bold font-italic">- Mục đích và phạm vi thu thập thông tin:</p>
            <p>Trong quá trình cung cấp dịch vụ, Công ty có thể thu thập dữ liệu cá nhân về Thành viên. Dữ liệu cá nhân mà website CONASI365.vn có thể thu thập bao gồm:</p>
            <ul>
                <li>Họ tên;</li>
                <li>Địa chỉ email;</li>
                <li>Địa chỉ;</li>
                <li>Tài khoản ngân hàng và thông tin thanh toán;</li>
                <li>Số điện thoại;</li>
                <li>Thông tin được gửi bởi hoặc liên quan đến (các) thiết bị được sử dụng để truy cập vào Các Dịch vụ hoặc Nền tảng của chúng tôi.</li>
            </ul>
            <p class="mt-4">Việc thu thập các thông tin nhằm mục đích phục vụ cho các giao dịch và hoạt động của thành viên liên quan đến website CONASI365.vn. Thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử của mình. Ngoài ra, thành viên có trách nhiệm thông báo kịp thời cho CONASI365.vn về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.</p>
            <p class="font-weight-bold font-italic">- Phạm vi sử dụng thông tin</p>
            <p>CONASI365.vn sử dụng thông tin thành viên cung cấp để:</p>
            <ul>
                <li>Cung cấp các dịch vụ đến Thành viên;</li>
                <li>Gửi thông tin đơn hàng;</li>
                <li>Gửi các thông báo về các hoạt động trao đổi thông tin giữa thành viên và website CONASI365.vn;</li>
                <li>Ngăn ngừa các hoạt động phá hủy tài khoản người dùng của thành viên hoặc các hoạt động giả mạo Thành viên;</li>
                <li>Liên lạc và giải quyết với Thành viên trong những trường hợp đặc biệt;</li>
                <li>Xác nhận và liên hệ với các bên có liên quan đến giao dịch tại website CONASI365.vnn; và</li>
                <li>Trong trường hợp có yêu cầu của pháp luật: CONASI365.vn có trách nhiệm hợp tác cung cấp thông tin cá nhân thành viên khi có yêu cầu từ cơ quan tư pháp bao gồm: Viện kiểm sát, tòa án, cơ quan công an điều tra liên quan đến hành vi vi phạm pháp luật nào đó của thành viên và việc tiết lộ như vậy là cần thiết một cách hợp lý để tuân thủ các quy trình pháp lý. Ngoài ra, không ai có quyền xâm phạm vào thông tin cá nhân của thành viên. </li>
            </ul>
            <p class="mt-4 font-weight-bold font-italic">- Thời gian lưu trữ thông tin</p>
            <p>Dữ liệu Cá nhân về Thành viên sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự Thành viên đăng nhập và thực hiện hủy bỏ. Còn lại trong mọi trường hợp dữ liệu cá nhân thành viên sẽ được lưu trữ, bảo mật trên máy chủ của website CONASI365.vn.</p>
            <p class="font-weight-bold font-italic">- Những người hoặc tổ chức có thể được tiếp cận với thông tin cá nhân</p>
            <p>Đối tượng được tiếp cận với thông tin cá nhân của thành viên thuộc một trong những trường hợp sau:</p>
            <ul>
                <li>Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi;</li>
                <li>Các nhà cung cấp có ký hợp đồng với Công ty về việc cung cấp dịch vụ cho khách hàng, các đơn vị cung cấp dịch vụ như cổng thanh toán. Các đối tác này sẽ nhận được những thông tin theo thỏa thuận hợp đồng (có thể một phần hoặc toàn bộ thông tin tùy theo điều khoản hợp đồng) để tiến hành hỗ trợ người dùng sử dụng dịch vụ do Công ty cung cấp;</li>
                <li>Cơ quan nhà nước khi có yêu cầu Công ty cung cấp thông tin thành viên để phục vụ quá trình điều tra.</li>
            </ul>
            <p class="mt-4 font-weight-bold font-italic">- Địa chỉ của đơn vị thu thập và quản lý thông tin cá nhân</p>
            <ul>
                <li>Tên đơn vị: Công Ty Cổ Phần Quản Lý Và Phát Triển Bất Động Sản Conasi</li>
                <li>Trụ sở chính: Lầu 8, 58 Nguyễn Đình Chiểu, Phường Đa Kao, Quận 1, TP Hồ Chí Minh.</li>
            </ul>
            <p class="mt-4">Khi cần kiểm tra về việc thu thập thông tin cá nhân trên CONASI365.vn, khách hàng có thể liên hệ tới đầu mối tiếp nhận thông tin:</p>
            <ul>
                <li>Bộ phận: Phòng Chăm sóc Khách hàng</li>
                <li>Email: info@conasi365.vn</li>
                <li>Điện thoại: 1900 63 68 70</li>
            </ul>
            <p class="mt-4 font-weight-bold font-italic">- Phương tiện và công cụ để người dùng tiếp cận và chỉnh sửa dữ liệu cá nhân của mình</p>
            <p>Thành viên có quyền tự kiểm tra, cập nhật, điều chỉnh hoặc hủy bỏ thông tin cá nhân của mình bằng cách đăng nhập vào tài khoản và chỉnh sửa thông tin cá nhân.</p>
            <p>Thành viên có quyền gửi yêu cầu để xin khôi phục tài khoản do quên mật khẩu bằng cách gửi email đến địa chỉ: info@conasi365.vn</p>
            <p>Ban Quản trị CONASI365.vn sẽ hướng dẫn thành viên thực hiện các bước để khôi phục tài khoản. Vui lòng lưu ý Ban Quản trị sẽ không can thiệp vào mật khẩu của Thành viên.</p>
            <p class="font-weight-bold font-italic">- Cam kết bảo mật thông tin cá nhân </p>
            <p>Thông tin cá nhân của thành viên trên website CONASI365.vn được cam kết bảo mật tuyệt đối theo Chính Sách Bảo Mật Thông Tin Cá Nhân của website CONASI365.vn. Việc thu thập và sử dụng thông tin của mỗi Thành viên chỉ được thực hiện khi có sự đồng ý của Thành viên đó trừ những trường hợp pháp luật có quy định khác;</p>
            <p>Không sử dụng, không chuyển giao, cung cấp hay tiết lộ cho bên thứ 3 nào về thông tin cá nhân của Thành viên khi không có sự cho phép đồng ý từ Thành viên;</p>
            <p>Trong trường hợp máy chủ lưu trữ thông tin bị hacker tấn công dẫn đến mất mát dữ liệu cá nhân Thành viên, CONASI365.vn sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và thông báo cho Thành viên được biết;</p>
            <p>CONASI365.vn yêu cầu các cá nhân, tổ chức khi đăng ký/mua hàng là Thành viên, phải cung cấp đầy đủ thông tin cá nhân có liên quan như: Họ và tên, địa chỉ liên lạc, email, số chứng minh nhân dân, điện thoại,…., và chịu trách nhiệm về tính pháp lý của những thông tin trên. Chúng tôi không chịu trách nhiệm cũng như không giải quyết mọi khiếu nại có liên quan đến quyền lợi của Thành viên đó nếu xét thấy tất cả thông tin cá nhân của Thành viên đó cung cấp khi đăng ký ban đầu là không chính xác.</p>
            <p class="font-weight-bold font-italic">- Cơ chế tiếp nhận và giải quyết khiếu nại liên quan đến việc thông tin cá nhân thành viên </p>
            <p>Thành viên có quyền khiếu nại về việc lộ thông tin cá nhân cho bên thứ 3 đến CONASI365.vn bằng cách gửi khiếu nại đến địa chỉ trụ sở của Conasi hoặc qua email: info@conasi365.vn.</p>
            <p>Conasi có trách nhiệm thực hiện các biện pháp kỹ thuật, nghiệp vụ để xác minh các nội dung được phản ánh.    </p>
            <p>Thời gian xử lý khiếu nại/phản ánh liên quan đến thông tin cá nhân khách hàng tối đa là 15 ngày làm việc kể từ ngày nhận được khiếu nại.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">VII. QUẢN LÝ THÔNG TIN XẤU</p>
            <p class="font-weight-bold">1. Quy định thành viên</p>
            <p>Thành viên sẽ tự chịu trách nhiệm về bảo mật và lưu giữ mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu của mình. Thành viên có trách nhiệm thông báo kịp thời cho website CONASI365.vn về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của bên thứ ba để có biện pháp giải quyết phù hợp.</p>
            <p>Thành viên không sử dụng dịch vụ của website CONASI365.vn vào những mục đích bất hợp pháp, không hợp lý, lừa đảo, đe doạ, thăm dò thông tin bất hợp pháp, phá hoại, tạo ra và phát tán virus gây hư hại tới hệ thống, cấu hình, truyền tải thông tin của website CCONASI365.vn hay sử dụng dịch vụ của mình vào mục đích đầu cơ, lũng đoạn thị trường tạo những đơn đặt hàng, chào hàng giả, kể cả phục vụ cho việc phán đoán nhu cầu thị trường. Trong trường hợp vi phạm thì thành viên phải chịu trách nhiệm về các hành vi của mình trước pháp luật.</p>
            <p>Thành viên không được thay đổi, chỉnh sửa, gán ghép, copy, truyền bá, phân phối, cung cấp và tạo những công cụ tương tự của dịch vụ do CONASI365.vn cung cấp cho một bên thứ ba nếu không được sự đồng ý của CONASI365.vn trong bản Quy chế này.</p>
            <p>Thành viên không được hành động gây mất uy tín của CONASI365.vn dưới mọi hình thức như gây mất đoàn kết giữa các thành viên bằng cách sử dụng tên đăng ký thứ hai, thông qua một bên thứ ba hoặc tuyên truyền, phổ biến những thông tin không có lợi cho uy tín của CONASI365.vn.</p>
            <p class="font-weight-bold">2. Quy định về đăng tải thông tin trên CONASI365.vn</p>
            <p>Đối với việc đăng tải sản phẩm, Ban quản trị CONASI365.vn chịu trách nhiệm đăng thông tin các chương trình khuyến mại, các voucher lên website.</p>
            <p>Nhà cung cấp có trách nhiệm cung cấp đầy đủ thông tin và giấy tờ theo quy định, bao gồm:</p>
            <ul>
                <li>Thông tin về các chương trình khuyến mại: thời gian phát hành, thời gian sử dụng, mức ưu đãi, điều kiện sử dụng, mô tả chi tiết, …</li>
                <li>Các giấy phép hợp lệ thực hiện các chương trình khuyến mại, giấy phép kinh doanh cho các hoạt động kinh doanh …</li>
            </ul>
            <p class="mt-4">Công ty sẽ kiểm duyệt những thông tin về sản phẩm, dịch vụ, thông tin ưu đãi khuyến mại mà nhà cung cấp gửi cho bên quản trị website để đăng bán. Các nội dung được bộ phận kiểm duyệt nội dung tiến hành kiểm tra trong không quá 48h kể từ khi nhận được từ nhà cung cấp và sẽ cho đăng tải dịch vụ/voucher hiển thị lên website khi đạt đủ các yêu cầu. </p>
            <p>Tất cả những thông tin sản phẩm vi phạm quy định đăng tin trong Đề án và nêu tại bản Quy chế hoạt động đều sẽ lọc bỏ và có thông báo bằng email cho nhà cung cấp để kịp thời điều chỉnh hoặc xóa bỏ. </p>
            <p class="font-weight-bold">3. Các sản phẩm dịch vụ bị cấm trên CONASI365.vn</p>
            <p>Danh sách sản phẩm cấm giao dịch:</p>
            <ul>
                <li>Rượu từ 15o trở lên;</li>
                <li>Thuốc lá điếu, xì gà và các dạng thuốc lá thành phẩm khác;</li>
                <li>Các loại pháo nổ, thuốc pháo nổ;</li>
                <li>Các chất ma túy;</li>
                <li>Thiết bị gây nhiễu thông tin di động tế bào;</li>
                <li>Đèn trời;</li>
                <li>Vũ khí quân dụng, trang thiết bị, kĩ thuật, khí tài, phương  tiện chuyên dùng quân sự, công an, quân trang;</li>
                <li>Đồ chơi nguy hiểm, đồ chơi có hại tới giáo dục nhân cách và sức khỏe của trẻ em hoặc tới an ninh, trật tự, văn hóa xã hội ( bao gồm cả các chương trình trò chơi điện tử);</li>
                <li>Các sản phẩm văn hóa phản động, đồi trụy, mê tín dị đoan hoặc có hại tới giáo dục thẩm mỹ, nhân cách;</li>
                <li>Di vật, cổ vật, bảo vật quốc gia thuộc di tích lịch sử văn hóa và danh lam thắng cảnh, thuộc sở hữu toàn dân, sở hữu của các tổ chức chính trị, tổ chức chính trị - xã hội;</li>
                <li>Hóa chất độc; Tiền chất;</li>
                <li>Thuốc lá điếu, xì gà và các dạng thuốc lá thành phẩm khác nhập lậu;</li>
                <li>Thực vật, động vật hoang dã;</li>
                <li>Thủy sản cấm khai thác, thủy sản có dư lượng  chất độc hại vượt  quá giới hạn cho phép, thủy sản có yếu tố độc tự nhiên gây nguy hiểm đến tính mạng con người;</li>
                <li>Các loại thuốc chữa bệnh cho người,  các loại vắc xin, sinh phẩm y tế, mỹ phẩm, hóa chất và chế phẩm diệt côn trùng, diệt khuẩn trong lĩnh vực gia dụng và y tế chưa được  sử dụng tại Việt Nam;</li>
                <li>Các loại trang thiết bị y tế chưa được phép sử dụng tại Việt Nam;</li>
                <li>Các loại  mỹ phẩm y tế chưa được công bố với cơ quan có thẩm quyền;</li>
                <li>Mua bán các loại xe không có giấy tờ hay mất giấy tờ (Chú ý khi mua bán xe phải có bên thứ 3 chứng giấy tờ); và</li>
                <li>Mua bán thông tin cá nhân (email, danh sách khách hàng...)</li>
            </ul>
            <p class="mt-4">Danh sách sản phẩm giao dịch có điều kiện:</p>
            <ul>
                <li>Đối với dụng cụ thể thao tập võ, thành viên bán hàng phải là cửa hàng dụng cụ thể thao hay cơ sở sản xuất dụng cụ thể thao;</li>
                <li>Đối với các mặt hàng giới tính như  bao cao su có thể  bán như  nhà thuốc, riêng sextoy, kích dục không được  bán; và</li>
                <li>Đối với các mặt hàng phần mềm như account, software, … có bản quyền, phải do công ty có giấy phép kinh doanh rõ ràng. Các cá nhân không được bán mặt hàng này.</li>
            </ul>
            <p class="mt-4">Danh sách quảng cáo không được đăng tải:</p>
            <ul>
                <li>Quảng cáo thiếu thẩm mỹ, trái với truyền thống lịch sử, văn hóa, đạo đức, thuần phong mỹ tục Việt Nam;</li>
                <li>Quảng cáo xúc phạm uy tín, danh dự, nhân phẩm của tổ chức, cá nhân;</li>
                <li>Quảng cáo có sử dụng hình ảnh, lời nói, chữ viết của cá nhân khi chưa được cá nhân đó đồng ý, trừ trường hợp được pháp luật cho phép;</li>
                <li>Quảng cáo xúc phạm uy tín, danh dự, nhân phẩm của tổ chức, cá nhân;</li>
                <li>Quảng cáo không đúng hoặc gây nhầm lẫn về khả năng kinh doanh, khả năng cung cấp sản phẩm, hàng hóa, dịch vụ của tổ chức, cá nhân kinh doanh sản phẩm, hàng hóa, dịch vụ; về số lượng, chất lượng, giá, công dụng, kiểu dáng, bao bì, nhãn hiệu, xuất xứ, chủng loại, phương thức phục vụ, thời hạn bảo hành của sản phẩm, hàng hoá, dịch vụ đã đăng ký hoặc đã được công bố;</li>
                <li>Quảng cáo bằng việc sử dụng phương pháp so sánh trực tiếp về giá cả, chất lượng, hiệu quả sử dụng sản phẩm, hàng hóa, dịch vụ của mình với giá cả, chất lượng, hiệu quả sử dụng sản phẩm, hàng hóa, dịch vụ cùng loại của tổ chức, cá nhân khác;</li>
                <li>Quảng cáo có nội dung cạnh tranh không lành mạnh theo quy định của pháp luật về cạnh tranh; và</li>
                <li>Quảng cáo vi phạm pháp luật về sở hữu trí tuệ.</li>
            </ul>
            <p class="mt-4 font-weight-bold">4. Cơ chế rà soát, kiểm soát thông tin của Ban quản trị CONASI365.vn đối với sản phẩm dịch vụ được đăng tải trên website:</p>
            <p>Nhà cung cấp gửi thông tin doanh nghiệp, các thông tin về giấy phép doanh nghiệp, giấy phép hoạt động kinh doanh sản phẩm dịch vụ muốn hợp tác với website CONASI365.vn đến Bộ phận kiểm duyệt nội dung để bộ phận này xác thực;</p>
            <p>Sau khi nhà cung cấp gửi thông tin, Bộ phận kiểm duyệt nội dung sẽ tiến hành xác minh, xác thực lại nội dung sẽ đăng bằng cách đối chiếu với quy định đăng tin và danh sách các sản phẩm bị cấm, hạn chế kinh doanh. Những nội dung vi phạm Bộ phận kiểm duyệt sẽ từ chối không đăng tải lên website;</p>
            <p>Đối với việc kiểm duyệt thông tin khách hàng phản ánh, Công ty sẽ tiến hành liên hệ trực tiếp đến người phản ánh để xác thực thông tin;</p>
            <p>Khi phát hiện những nội dung thông tin sai phạm, thông tin về sản phẩm/dịch vụ của nhà cung cấp không đúng như mô tả, Bộ phận kiểm duyệt nội dung sẽ tiến hành xóa bỏ nội dung đó và có gửi cảnh cáo đến nhà cung cấp;</p>
            <p>Conasi sẽ toàn quyền loại bỏ các sản phẩm và dịch vụ của nhà cung cấp nếu vi phạm quy chế đăng tin. Các thông tin không phù hợp sẽ được yêu cầu điều chỉnh hoặc gỡ bỏ.</p>
            <p>Conasi giữ quyền quyết định về việc lưu giữ hay loại bỏ sản phẩm dịch vụ đã đăng trên website và sẽ thông báo cho nhà cung cấp được biết.</p>
            <p>Chế tài nếu nhà cung cấp vi phạm quy định:</p>
            <p><span class="font-weight-bold font-italic">- Nếu vi phạm lần 1: </span>Công ty sẽ gửi email đến cho nhà cung cấp vi phạm, kèm theo các nội dung đã vi phạm để thông báo và xóa bỏ những nội dung vi phạm.</p>
            <p><span class="font-weight-bold font-italic">- Nếu vi phạm lần 2: </span>Công ty sẽ gửi email/thông báo hệ thống đến cho nhà cung cấp vi phạm, kèm theo nội dung đã vi phạm, đồng thời đóng thông tin dịch vụ của đối tác. Công ty sẽ căn cứ các biện pháp của nhà cung cấp đưa ra để cân nhắc về việc mở lại thông tin của nhà cung cấp trên website.</p>
            <p><span class="font-weight-bold font-italic">- Nếu vi phạm lần 3: </span>Conasi sẽ chấm dứt thỏa thuận với nhà cung cấp. Nhà cung cấp có trách nhiệm hoàn thành các nghĩa vụ theo thỏa thuận ban đầu.</p>
            <p>Trong trường hợp khách hàng không được cung cấp dịch vụ, các ưu đãi, khuyến mại như voucher phát hành mà Công ty chứng minh được lỗi thuộc về nhà cung cấp, Công ty sẽ phản hồi và  yêu cầu nhà cung cấp thực hiện các cam kết dịch vụ như đã công bố và tiến hành bồi hoàn chi phí mà khách hàng đã phải bỏ ra khi đăng ký dịch vụ thông qua Công ty. </p>
            <p>Nếu nhà cung cấp không thực hiện bồi thường khi lỗi thuộc về mình, Công ty sẽ gửi email/thông báo hệ thống đến cho nhà cung cấp, kèm theo nội dung đã vi phạm, đồng thời đóng thông tin dịch vụ của nhà cung cấp trên CONASI365.vn. Chúng tôi sẽ căn cứ các biện pháp của nhà cung cấp đưa ra để cân nhắc về việc mở lại thông tin trên website cấp.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">VIII. TRÁCH NHIỆM TRONG TRƯỜNG HỢP PHÁT SINH LỖI KỸ THUẬT</p>
            <p>Website CONASI365.vn cam kết nỗ lực đảm bảo sự an toàn và ổn định của toàn bộ hệ thống kỹ thuật. Tuy nhiên, trong trường hợp xảy ra sự cố do lỗi của CONASI365.vn, chúng tôi sẽ ngay lập tức áp dụng các biện pháp để đảm bảo quyền lợi cho thành viên.</p>
            <p>Khi thực hiện các giao dịch trên CONASI365.vn, bắt buộc các thành viên phải thực hiện đúng theo các quy trình hướng dẫn.</p>
            <p>Ban quản lý CONASI365.vn cam kết cung cấp chất lượng dịch vụ tốt nhất cho các Thành viên tham gia giao dịch. Trường hợp phát sinh lỗi kỹ thuật, lỗi phần mềm hoặc các lỗi khách quan khác dẫn đến thành viên không thể tham gia giao dịch được thì các Thành viên thông báo cho Ban quản lý website tại địa chỉ email: info@conasi365.vn chúng tôi sẽ khắc phục lỗi trong thời gian sớm nhất, tạo điều kiện cho các thành viên tham gia giao dịch tại Conasi365.vn.</p>
            <p>Tuy nhiên, Ban quản lý website CONASI365.vn sẽ không chịu trách nhiệm giải quyết trong trường hợp thông báo của các thành viên không đến được Ban quản lý, phát sinh từ lỗi kỹ thuật, lỗi đường truyền, phần mềm hoặc các lỗi khác không do Ban quản lý gây ra.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">IX. QUYỀN VÀ NGHĨA VỤ CỦA BAN QUẢN LÝ WEBSITE CUNG CẤP DỊCH VỤ THƯƠNG MẠI ĐIỆN TỬ CONASI365.vn</p>
            <p class="font-weight-bold">1. Quyền của Ban quản lý website cung cấp dịch vụ thương mại điện tử CONASI365.vn</p>
            <p>Website CONASI365.vn sẽ tổ chức giới thiệu, cung cấp các sản phẩm, dịch vụ của các nhà cung cấp, thực hiện quảng cáo các chương trình khuyến mại với mức giá ưu đãi đến khách hàng đăng ký tham gia sau khi đã hoàn thành các thủ tục đăng ký kê khai trực tuyến các thông tin cá nhân có liên quan và các điều kiện bắt buộc mà CONASI365.vn nêu ra.</p>
            <p>CONASI365.vn sẽ cung cấp thông tin các chương trình khuyến mại, quảng cáo, voucher sản phẩm, dịch vụ đến các khách hàng mua theo sự đồng ý của nhà cung cấp thực hiện khuyến mại trên website CONASI365.vn.</p>
            <p>CONASI365.vn có quyền yêu cầu nhà cung cấp cung cấp đầy đủ các thông tin về doanh nghiệp, về sản phẩm hàng hóa dịch vụ cũng như các giấy tờ hoạt động có liên quan và yêu cầu hợp tác từ phía nhà cung cấp trong quá trình thực hiện công việc của mình. </p>
            <p>CONASI365.vn có quyền từ chối, tạm ngừng hoặc chấm dứt ngay tư cách của nhà cung cấp bất cứ thời điểm nào nếu CONASI365.vn nhận định rằng việc tiếp tục cho phép nhà cung cấp giao dịch có khả năng sẽ gây thiệt hại cho CONASI365.vn và/hoặc Bên khác trên CONASI365.vn mà không cần có sự đồng ý của nhà cung cấp. Tuy nhiên, có thể CONASI365.vn sẽ thông báo trước cho nhà cung cấp về việc tạm ngừng hoặc chấm dứt này (trước 01 tháng).</p>
            <p>CONASI365.vn có thể chấm dứt ngay quyền sử dụng dịch vụ và quyền của Khách hàng nếu CONASI365.vn phát hiện Khách hàng đã phá sản, bị kết án hoặc đang trong thời gian thụ án;  trong trường hợp Khách hàng tiếp tục hoạt động có thể gây cho CONASI365.vn trách nhiệm pháp lý; hoặc Khách hàng có những hoạt động lừa đảo, giả mạo, gây rối loạn thị trường, gây mất đoàn kết đối với các Khách hàng khác của CONASI365.vn, hoạt động vi phạm pháp luật hiện hành của Việt Nam. Trong trường hợp chấm dứt quyền Khách hàng và quyền sử dụng dịch vụ thì tất cả các chứng nhận, các quyền của Khách hàng được cấp sẽ mặc nhiên hết giá trị và bị chấm dứt. </p>
            <p>Trong trường hợp Khách hàng vi phạm các Quy chế của CONASI365.vn hoặc có những hành vi ảnh hưởng đến hoạt động trên CONASI365.vn, chúng tôi có thể chấm dứt quyền Khách hàng và quyền sử dụng một hoặc tất cả các dịch vụ của Khách hàng và sẽ thông báo cho Khách hàng trong thời hạn ít nhất là một (01) tháng.</p>
            <p>Nhà cung cấp cho phép cho CONASI365.vn quyền sử dụng tên, logo, thông tin liên quan trên các tài liệu tiếp thị và được xem như một nguồn phục vụ cho công việc/liên doanh mà CONASI365.vn cung cấp, và được quyền xuất bản và công báo về toàn bộ tiến triển và công việc hợp tác trong Hợp đồng, miễn là không tiết lộ bí mật thương mại, thông tin kinh doanh mà không có sự đồng ý của nhà cung cấp.</p>
            <p>CONASI365.vn giữ bản quyền sử dụng dịch vụ và các nội dung trên CONASI365.vn theo các quy định pháp luật về bảo hộ sở hữu trí tuệ tại Việt Nam. Tất cả các biểu tượng, nội dung theo các ngôn ngữ khác nhau đều thuộc quyền sở hữu của CONASI365.vn. Nghiêm cấm mọi hành vi sao chép, sử dụng và phổ biến bất hợp pháp các quyền sở hữu trên.</p>
            <p>Trường hợp CONASI365.vn phát hiện Nhà cung cấp vi phạm các quy định về việc sử dụng giao diện, thiết kế, ảnh trình bày (layout) các hình, thông tin mà không được sự đồng ý trước bằng văn bản, CONASI365.vn có quyền:</p>
            <ul>
                <li>Yêu cầu nhà cung cấp chấm dứt ngay hành vi vi phạm; và/hoặc Không xác nhận, từ chối hoặc hủy các gian hàng, đơn đặt hàng phát sinh từ hành vi vi phạm nêu trên; và/hoặc</li>
                <li>Khấu trừ mọi chi phí mà CONASI365.vn đã bỏ ra (nếu có) để triển khai chương trình khuyến mại vào các khoản phải thanh toán cho Nhà cung cấp theo các hợp đồng đã ký kết giữa Nhà cung cấp và CONASI365.vn. CONASI365.vn sẽ tiến hành cung cấp các dịch vụ cho Nhà cung cấp sau khi đã hoàn thành các thủ tục và các điều kiện bắt buộc mà CONASI365.vn nêu ra</li>
            </ul>
            <p class="mt-4">CONASI365.vn giữ quyền được thay đổi bảng, biểu giá dịch vụ và phương thức thanh toán trong thời gian cung cấp dịch vụ cho Khách hàng theo nhu cầu và điều kiện khả năng của CONASI365.vn và sẽ báo trước cho Khách hàng thời hạn là một (01) tháng.</p>
            <p>Có quyền được thanh toán đầy đủ và đúng hạn theo quy định của hợp đồng. Có quyền yêu cầu khắc phục và bồi thường trong trường hợp Nhà cung cấp vi phạm bất cứ nghĩa vụ nào theo Hợp đồng và/hoặc gây thiệt hại cho CONASI365.vn.</p>
            <p class="font-weight-bold">2. Nghĩa vụ và trách nhiệm của Ban quản lý Website cung cấp dịch vụ thương mại điện tử CONASI365.vn</p>
            <p>CONASI365.vn có trách nhiệm đăng ký, thiết lập website cung cấp dịch vụ thương mại điện tử theo quy định của pháp luật và công bố các thông tin đã đăng ký trên website CONASI365.vn.</p>
            <p>Xây dựng và công bố công khai trên website quy chế hoạt động theo quy định của pháp luật; theo dõi và bảo đảm việc thực hiện quy chế hoạt động của các đối tác và khách hàng.</p>
            <p>Website CONASI365.vn có trách nhiệm xây dựng, thực hiện “cơ chế kiểm tra, giám sát để đảm bảo việc cung cấp thông tin của Nhà cung cấp trên website được thực hiện chính xác đầy đủ” theo quy định tại Khoản 4 Điều 36 Nghị định 52/2013/NĐ-CP. </p>
            <p>CONASI365.vn có trách nhiệm yêu cầu các Nhà cung cấp phải cung cấp đầy đủ và chịu trách nhiệm pháp lý về các thông tin có liên quan đến sản phẩm, dịch vụ, cũng như thông tin về các chương trình khuyến mại. Nếu sản phẩm, dịch vụ của Nhà cung cấp thuộc danh mục hàng hóa, dịch vụ kinh doanh có điều kiện thì CONASI365.vn cần yêu cầu Nhà cung cấp phải cung cấp giấy chứng nhận đủ điều kiện kinh doanh đối với Sản Phẩm đó (trong trường hợp pháp luật quy định phải có giấy chứng nhận đủ điều kiện kinh doanh). Nếu là các chương trình khuyến mại, CONASI365.vn cần yêu cầu Nhà cung cấp phải cung cấp giấy phép tổ chức các chương trình. </p>
            <p>CONASI365.vn có trách nhiệm kiểm tra, giám sát để đảm bảo việc cung cấp thông tin hoặc đăng tải các chương trình khuyến mại trên của Nhà cung cấp website CONASI365.vn được thực hiện chính xác, đầy đủ. Xử lý khi phát hiện các hành vi kinh doanh vi phạm pháp luật trên website CONASI365.vn. </p>
            <p>Loại bỏ khỏi website CONASI365.vn những thông tin dịch vụ vi phạm quyền sở hữu trí tuệ và các dịch vụ vi phạm pháp luật khác khi phát hiện hoặc nhận được phản ánh có căn cứ xác thực về những thông tin này. </p>
            <p>Ngăn chặn và loại bỏ khỏi CONASI365.vn những thông tin hàng hóa, dịch vụ thuộc danh mục cấm kinh doanh theo quy định của Pháp luật và sản phẩm dịch vụ hạn chế kinh doanh hoặc kinh doanh có điều kiện trên CONASI365.vn; </p>
            <p>CONASI365.vn có trách nhiệm thiết lập cơ chế cho phép người dùng tham gia website CONASI365.vn được thực hiện được quy trình giao kết hợp đồng theo quy định tại Mục 2 Chương II Nghị định 52/2013/NĐ-CP.</p>
            <p>Website CONASI365.vn cần đảm bảo an toàn, bảo mật giao dịch thanh toán của khách hàng, xử lý khiếu nại và đền bù thiệt hại trong trường hợp thông tin thanh toán của khách hàng qua website bị thay đổi, xóa, hủy, sao chép, tiết lộ, di chuyển trái phép hoặc bị chiếm đoạt.</p>
            <p>Website CONASI365.vn có trách nhiệm xây dựng và công bố trên website cơ chế tiếp nhận, giải quyết các khiếu nại của khách hàng về chất lượng hàng hóa, dịch vụ được khuyến mại hoặc hàng hóa, dịch vụ được dùng để khuyến mại. CONASI365.vn chịu trách nhiệm của bên thứ ba trong việc cung cấp hàng hóa, dịch vụ cho người tiêu dùng.</p>
            <p>Công khai cơ chế giải quyết các tranh chấp phát sinh trong quá trình giao dịch trên CONASI365.vn. Khi có mâu thuẫn giữa khách hàng và đối tác người bán, CONASI365.vn có trách nhiệm cung cấp cho khách, hàng thông tin về nhà cung cấp tích cực hỗ trợ khách hàng bảo vệ quyền lợi hợp pháp của mình. </p>
            <p>Website CONASI365.vn có trách nhiệm đứng ra làm trung gian trong việc hòa giải, phối hợp với nhà cung cấp giải quyết những vấn đề tranh chấp, khiếu nại với khách hàng trong quá trình cung cấp dịch vụ thông qua CONASI365.vn. CONASI365.vn sẽ yêu cầu nhà cung cấp có nghĩa vụ phải bồi thường những thiệt hại của khách hàng khi lỗi được xác định thuộc về Nhà cung cấp.</p>
            <p>CONASI365.vn cam kết các kênh thức sẽ hỗ trợ chính được áp dụng trong vòng 72 giờ từ khi nhận được đơn hàng.</p>
            <p>CONASI365.vn cũng chịu trách nhiệm bồi thường cho khách hàng nếu các voucher do mình phát hành bị đối tác từ chối trái với các điều kiện đã công bố trên website hoặc trên chính voucher đó. </p>
            <p>CONASI365.vn có trách nhiệm giao voucher hoặc hàng hóa dịch vụ cho khác hàng (khi có thỏa thuận với nhà cung cấp) và thu hộ cho nhà cung cấp. CONASI365.vn có trách nhiệm thanh toán các khoản phí thu đã thỏa thuận cho Nhà cung cấp sau khi trừ đi các khoản phí và hoa hồng.</p>
            <p>CONASI365.vn có trách nhiệm xuất hóa đơn cho khách hàng mua tại thời điểm cung cấp E-voucher.</p>
            <p>CONASI365.vn sẽ không chia sẻ dữ liệu cá nhân của khách hàng cho đối tác nhiều hơn các thông tin cần thiết như họ, tên, hình đại diện, số điện thoại và số liệu thống kê khác. Trong trường hợp xảy ra bất kỳ vấn đề gì với khách hàng, các bên hiểu rằng CONASI365.vn không có trách nhiệm thẩm tra khách hàng. Tuy nhiên CONASI365.vn sẽ nỗ lực hợp lý để hợp tác với đối tác và các cơ quan có thẩm quyền trong trường hợp pháp luật Việt Nam có quy định.</p>
            <p>CONASI365.vn có trách nhiệm hỗ trợ cơ quan quản lý nhà nước điều tra các hành vi kinh doanh vi phạm pháp luật, cung cấp thông tin đăng ký, lịch sử giao dịch và các tài liệu khác về đối tượng có hành vi vi phạm pháp luật trên CONASI365.vn.</p>
            <p>Website CONASI365.vn chịu trách nhiệm xây dựng một số công việc chính như: nghiên cứu, thiết kế, mua sắm các thiết bị phần cứng và phần mềm, kết nối Internet, xây dựng chính sách CONASI365.vn trong điều kiện và phạm vi cho phép. Website CONASI365.vn sẽ tiến hành triển khai và hợp tác với các nhà cung cấp trong việc xây dựng hệ thống các dịch vụ, các công cụ tiện ích phục vụ cho việc giao dịch của các Khách hàng tham gia trên CONASI365.vn.</p>
            <p>Website CONASI365.vn sẽ cố gắng đến mức cao nhất trong phạm vi và điều kiện có thể để duy trì hoạt động bình thường của CONASI365.vn và khắc phục theo các điều khoản và điều kiện của Hợp đồng. Tuy nhiên nếu những sự cố trên xảy ra nằm ngoài khả năng kiểm soát, là những trường hợp bất khả kháng mà gây thiệt hại cho Khách hàng thì CONASI365.vn không phải chịu trách nhiệm liên đới.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">X. QUYỀN VÀ TRÁCH NHIỆM CỦA NHÀ CUNG CẤP</p>
            <p class="font-weight-bold">1. Quyền của nhà cung cấp</p>
            <p>Sau khi ký thỏa thuận vể hợp tác cung ứng sản phẩm/ dịch vụ qua hệ thống CONASI365.vn, Nhà cung cấp sẽ được hỗ trợ biên tập các thông tin về sản phẩm/ dịch vụ, về các chương trình quảng cáo, khuyến mại và đăng lên CONASI365.vn.</p>
            <p>Nhà cung cấp dịch vụ sẽ được hỗ trợ bởi bộ phận chăm sóc, tư vấn của CONASI365.vn nếu được yêu cầu.</p>
            <p>Nhà cung cấp được hưởng các chính sách ưu đãi do ban quản lý website đề ra trong quá trình hoạt động.</p>
            <p>Nhà cung cấp được nhận lại khoản thanh toán cho các sản phẩm dịch vụ mình đã cung cấp cho khách hàng đặt hàng/dịch vụ qua CONASI365.vn.</p>
            <p class="font-weight-bold">2. Trách nhiệm nhà cung cấp</p>
            <p>Nhà cung cấp khi liên hệ hợp tác phải cung cấp đầy đủ thông tin như tên công ty, số CMND/MST, ngành nghề lĩnh vực hoạt động, website (nếu có),… cho Ban Quản lý CONASI365.vn.</p>
            <p>Nhà cung cấp sẽ cung cấp bản sao giấy phép kinh doanh, giấy chứng nhận kinh doanh, chính sách bảo hiểm trách nhiệm nếu là công ty du lịch, hướng dẫn quản lý an toàn, kế hoạch vận hành khi có sự cố khẩn cấp, …. cho CONASI365.vn trong vòng 07 (bảy) ngày trước khi ký kết hợp tác. Trong trường hợp Nhà cung cấp dịch vụ là chủ sở hữu một thương hiệu một hãng hoặc đại lý ủy quyền của nhà đầu tư thì Nhà cung cấp dịch vụ có trách nhiệm cung cấp một (01) bản photo hợp đồng đại lý hoặc giấy Ủy quyền phân phối của nhà đầu tư đó. Mọi thay đổi đối với thông tin được nêu ở trên sẽ được Nhà cung cấp gửi tới CONASI365.vn bằng văn bản kèm theo các tài liệu được cập nhật trong vòng mười (10) ngày sau khi có thay đổi.</p>
            <p>Đối với chương trình khuyến mại, nhà cung cấp cần cung cấp đầy đủ thông tin về sản phẩm/ dịch vụ mỗi chương trình khuyến mại: giá cả của một hoặc nhiều chủng loại sản phẩm/ dịch vụ, thông tin sản phẩm/dịch vụ, thương hiệu sản phẩm/ dịch vụ được áp dụng trong chương trình khuyến mại, địa điểm khuyến mại, các điều kiện áp dụng. Đồng thời cung cấp đầy đủ các giấy phép hoạt động kinh doanh, giấy phép các chương trình khuyến mại, quảng cáo đã được cơ quan có thẩm quyền cấp phép.</p>
            <p>Nhà cung cấp dịch vụ phải cam kết các thông tin, giấy tờ cung cấp là chính xác và phải là bản gốc hoặc bản sao y có công chứng của chính quyền địa phương.</p>
            <p>Nhà cung cấp dịch vụ phải đảm bảo số lượng và chất lượng dịch vụ cam kết như trên website, và chịu toàn bộ trách nhiệm liên quan đến dịch vụ cung cấp khi thực hiện chương trình khuyến mại.</p>
            <p>Nhà cung cấp cần thông báo nội dung chương trình khuyến mại này cho toàn thể nhân viên của mình. Có thái độ phục vụ tận tâm chu đáo, chuẩn bị đội ngũ nhân viên đảm bảo phục vụ khách hàng tốt nhất trong thời gian voucher có hiệu lực. Tiếp nhận, và không phân biệt đối xử đối với khách hàng sử dụng dịch vụ khuyến mại.</p>
            <p>Nhà cung cấp phải tuân thủ nghiêm ngặt các tiêu chuẩn nghề nghiệp liên quan để cung cấp dịch vụ cho khách hàng. </p>
            <p>Bất kỳ đơn hàng nào không được Nhà cung cấp thêm vào hệ thống, dẫn đến xung đột trong việc đặt sản phẩm dịch vụ của khách hàng sử dụng CONASI365.vn, thì nhà cung cấp sẽ phải ưu tiên người sử dụng CONASI365.vn.</p>
            <p>Nhà cung cấp cần xây dựng và công bố cơ chế tiếp nhận giải quyết khiếu nại về chất lượng hàng hóa dịch vụ được đăng tải, về thông tin các chương trình khuyến mại hoặc hàng hóa dịch vụ dùng để khuyến mại của nhà cung cấp. Các thông tin này được công khai hoặc thông báo với khách hàng khi mua hàng hóa và tham gia các chương trình khuyến mại.</p>
            <p>Nhà cung cấp có trách nhiệm phối hợp với Công ty giải quyết những vấn đề tranh chấp giữa Khách hàng với nhà cung cấp về những khiếu nại trong quá trình cung cấp dịch vụ. Nhà cung cấp có nghĩa vụ phải bồi thường những thiệt hại của Khách hàng khi lỗi được xác định thuộc về Nhà cung cấp. </p>
            <p>Nhà cung cấp có trách nhiệm cung cấp thông tin về tình hình kinh doanh của mình khi có yêu cầu của cơ quan nhà nước có thẩm quyền để phục vụ hoạt động thống kê thương mại điện tử.</p>
            <p>Nhà cung cấp cần tuân thủ các quy định của pháp luật, khi tổ chức chương trình khuyến mại, cũng như các quy định về thanh toán, bảo vệ quyền sở hữu trí tuệ, bảo vệ quyền của người tiêu dùng và các quy định của pháp luật có liên quan khi tham gia giao dịch qua Conasi365.vn.</p>
            <p>Nhà cung cấp có trách nhiệm xuất hóa đơn cho CONASI365.vn cho các khoản thanh toán.</p>
            <p>Nhà cung cấp có trách nhiệm thực hiện đầy đủ nghĩa vụ thuế theo quy định của pháp luật.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">XI. QUYỀN VÀ TRÁCH NHIỆM KHÁCH HÀNG THAM GIA WEBSITE CUNG CẤP DỊCH VỤ THƯƠNG MẠI ĐIỆN TỬ CONASI365.vn</p>
            <p class="font-weight-bold">1. Quyền của khách hàng </p>
            <p>Khách Khách hàng sẽ được đăng ký một tài khoản đăng nhập và mật khẩu riêng để được vào sử dụng trong việc quản lý những giao dịch tại CONASI365.vn, dùng tính năng đặt hàng trực tuyến hoặc đặt mua voucher khuyến mại của các nhà cung cấp được cập nhật trên CONASI365.vn.</p>
            <p>Khách hàng cũng có quyền đăng bình luận và đánh giá của mình trên CONASI365.vn.</p>
            <p>Khách hàng sẽ được nhân viên của CONASI365.vn hướng dẫn sử dụng được các công cụ, các tính năng phục vụ cho việc đặt hàng và sử dụng các dịch vụ tiện ích trên CONASI365.vn.</p>
            <p>Khách hàng sau khi đã thanh toán dịch vụ được CONASI365.vn hướng dẫn sử dụng voucher để sử dụng dịch vụ tại nhà cung cấp hoặc nhận sản phẩm hàng hóa từ Nhà cung cấp. Khách hàng được đảm bảo cung cấp sản phẩm dịch vụ và các chương tình ưu đãi như thông tin cam kết của Nhà cung cấp. Trường hợp có vấn đề mà nguyên nhân do lỗi từ nhà cung cấp hay từ phía CONASI365.vn khiến khách hàng không nhận được sản phẩm dịch vụ như đã nêu, khách hàng sẽ được bồi thường thiệt hại, được chọn đổi sản phẩm dịch vụ hoặc được hoàn tiền nếu được yêu cầu. </p>
            <p>Khách hàng có quyền đóng góp ý kiến cho CONASI365.vn trong quá trình hoạt động. Các kiến nghị được gửi trực tiếp bằng thư, fax hoặc email đến cho CONASI365.vn. Duy trì thông tin liên lạc với CONASI365.vn một cách kịp thời và thông suốt cho việc vận hành dịch vụ.</p>
            <p>Nhận hóa đơn GTGT khi thanh toán đầy đủ phí dịch vụ đã đặt thông qua CONASI365.vn.</p>
            <p class="font-weight-bold">2. Trách nhiệm của khách hàng</p>
            <p>Khách hàng sẽ tự chịu trách nhiệm về bảo mật và lưu giữ và mọi hoạt động sử dụng dịch vụ dưới tên đăng ký, mật khẩu và hộp thư điện tử của mình. </p>
            <p>Khách hàng có trách nhiệm thông báo kịp thời cho CONASI365.vn về những hành vi sử dụng trái phép, lạm dụng, vi phạm bảo mật, lưu giữ tên đăng ký và mật khẩu của mình để hai bên cùng hợp tác xử lý. </p>
            <p>Khách hàng cam kết những thông tin cung cấp cho CONASI365.vn và những thông tin đang tải lên CONASI365.vn là chính xác.</p>
            <p>Khách hàng cam kết, đồng ý không sử dụng dịch vụ của CONASI365.vn vào những mục đích bất hợp pháp, không hợp lý, lừa đảo, đe doạ, thăm dò thông tin bất hợp pháp, phá hoại, tạo ra và phát tán virus gây hư hại tới hệ thống, cấu hình, truyền tải thông tin của CONASI365.vn hay sử dụng dịch vụ của mình vào mục đích đầu cơ, lũng đoạn thị trường tạo những đơn đặt hàng, chào hàng giả, kể cả phục vụ cho việc phán đoán nhu cầu thị trường. Trong trường hợp vi phạm thì Khách hàng phải chịu trách nhiệm về các hành vi của mình trước pháp luật.</p>
            <p>Khách hàng cam kết không được thay đổi, chỉnh sửa, sao chép, truyền bá, phân phối, cung cấp và tạo những công cụ tương tự của dịch vụ do CONASI365.vn cung cấp cho một bên thứ ba nếu không được sự đồng ý của CONASI365.vn trong Quy định này.</p>
            <p>Khách hàng không được hành động gây mất uy tín của CONASI365.vn dưới mọi hình thức như gây mất đoàn kết giữa các Khách hàng bằng cách sử dụng tên đăng ký thứ hai, thông qua một bên thứ ba hoặc tuyên truyền, phổ biến những thông tin không có lợi cho uy tín của Conasi365.vn.</p>
            <p>Thành viên cam kết không sử dụng hệ thống/giải pháp/trang web/website/phần mềm khác có thể gây xung đột lợi ích trực tiếp đến CONASI365.vn.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">XI.    ĐIỀU KHOẢN ÁP DỤNG</p>
            <p>Website CONASI365.vn có quyền và có thể thay đổi Quy chế này bằng cách thông báo lên Website cho các Đối tác và khách hàng biết. Việc thành viên tiếp tục sử dụng dịch vụ sau khi Quy chế sửa đổi được công bố và thực thi đồng nghĩa với việc thành viên đã chấp nhận Quy chế sửa đổi này.</p>
            <p>Quy chế hoạt động sẽ được website CONASI365.vn cập nhật bổ sung liên tục và thông báo trước khi thực hiện/có hiệu lực thi hành là 05 (năm) ngày làm việc. Đối tác và khách hàng tham gia website CONASI365.vn có trách nhiệm tuân theo quy chế hiện hành khi giao dịch trên website.</p>
        </div>
        <div class="text-left">
            <p class="font-weight-bold">XII.    ĐIỀU KHOẢN CAM KẾT</p>
            <p>Mọi khách hàng và Nhà cung cấp khi sử dụng website CONASI365.vn làm giao dịch mua bán trực tuyến thì đồng nghĩa việc các bên có liên quan đã chấp thuận tuân theo quy chế này.</p>
            <p>Mọi thắc mắc của khách hàng xin vui lòng liên hệ với website CONASI365.vn theo thông tin dưới đây để được  giải đáp:</p>
            <p class="font-weight-bold font-italic">- Hỗ trợ khách hàng</p>
            <ul>
                <li>Website cung cấp dịch vụ thương mại điện tử CONASI365.vn</li>
                <li>Địa chỉ: 58 Nguyễn Đình Chiểu, P Đa Kao, Quận 1, TPHCM</li>
                <li>E-mail:  info@conasi365.vn</li>
                <li>Hotline: 1900 63 68 70</li>
            </ul>
        </div>
        <div class="text-right">
            <span>Tổng giám đốc</span>
            <span class="d-block">NGUYỄN QUANG TRUNG</span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style>

</style>